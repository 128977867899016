<template>
  <Icon :src="chevronSrc" />
</template>

<script>
import Icon from '@/components/icons/Icon.vue';
import IcChevS from '@/assets/icons/chevron/ic-chev-s.svg';
import IcChevE from '@/assets/icons/chevron/ic-chev-e.svg';
import IcChevELight from '@/assets/icons/chevron/ic-chev-e-light.svg';

export default {
  name: 'Chevron',
  components: {
    Icon,
  },
  props: {
    side: Boolean,
    light: Boolean,
  },
  computed: {
    chevronSrc() {
      if (this.side) return this.light ? IcChevELight : IcChevE;
      return IcChevS;
    },
  },
};
</script>
