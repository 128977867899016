<template>
  <div class="left-scrollbar" :style="{ maxHeight: height }">
    <div class="left-scrollbar-children">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'LeftScrollbar',
  props: {
    height: String,
  },
};
</script>

<style scoped>
.left-scrollbar {
  padding-right: 100%;
  margin-right: -100%;
  overflow-y: auto;
  direction: rtl;
}

.left-scrollbar-children {
  direction: ltr;
  pointer-events: auto;
}
</style>
