import _axios from 'axios';
import { CURRENT_TOKEN, URL } from '@/services/config';
// @ts-ignore
import { refresh } from '@/services/auth';

const axios = _axios.create({
  baseURL: URL,
});

axios.interceptors.request.use(config => {
  const token = localStorage.getItem(CURRENT_TOKEN);
  config.headers.Authorization = `Bearer ${token}`;
  return config;
});

axios.interceptors.response.use(
  response => response,
  error => {
    const originalRequest = error.config;

    if (error.response.status === 401 && !originalRequest._isRetry) {
      originalRequest._isRetry = true;

      return refresh()
        .then((res: any) => {
          localStorage.setItem(CURRENT_TOKEN, res.auth.token);

          return axios(originalRequest);
        })
        .catch(() => {
          return axios(originalRequest);
        });
    }

    // return Error object with Promise
    return Promise.reject(error);
  }
);

export function deleteDevice(operatorId: number, deviceUser: number) {
  return axios.delete(`/admin/operators/${operatorId}/sign/cms/devices/${deviceUser}`);
}

export function deleteLayout(operatorId: number, layoutId: number) {
  return axios.delete(`/admin/operators/${operatorId}/sign/cms/layouts/${layoutId}`);
}

export function deleteMedia(operatorId: number, mediaId: number) {
  return axios.delete(`/admin/operators/${operatorId}/sign/cms/media/${mediaId}`);
}

export function deletePlaylist(operatorId: number, playlistId: number) {
  return axios.delete(`/admin/operators/${operatorId}/sign/cms/playlists/${playlistId}`);
}

export function deleteScreen(operatorId: number, screenId: number) {
  return axios.delete(`/admin/operators/${operatorId}/sign/cms/screens/${screenId}`);
}

export function deleteSound(operatorId: number, soundIds: any) {
  return axios.delete(`/admin/operators/${operatorId}/sign/sounds`, { data: soundIds });
}

export function deleteSoundGroup(operatorId: number, soundGroupId: number) {
  return axios.delete(`/admin/operators/${operatorId}/sign/sounds/groups/${soundGroupId}`);
}

export function unlinkScreen(operatorId: number, deviceUserId: number) {
  return axios.delete(
    `/admin/operators/${operatorId}/sign/cms/devices/${deviceUserId}/unlink/screen`
  );
}

export function unlinkOnBoard(operatorId: number, deviceUserId: number) {
  return axios.delete(
    `/admin/operators/${operatorId}/sign/cms/devices/${deviceUserId}/unlink/onboard`
  );
}

export function resetDevice(operatorId: number, deviceUserIds: number | number[]) {
  const url = `/admin/operators/${operatorId}/sign/cms/devices${
    Array.isArray(deviceUserIds) ? '' : `/${deviceUserIds}`
  }`;
  const data = Array.isArray(deviceUserIds) ? { dvusr: deviceUserIds } : null;

  return axios.post(`${url}/restart`, data);
}

export function deleteNetworkArcSounds(operatorId: number, network: any, version: number) {
  return axios.delete(
    `/admin/operators/${operatorId}/sign/systems/${network.sys_id}/layers/${network.lyr_id}/networks/${network.net_id}/versions/${version}/arcs`
  );
}

export function getContentGroups(operatorId: number, contentGroupId?: number) {
  let url = `/admin/operators/${operatorId}/sign/cms/content`;
  if (contentGroupId) url += `/${contentGroupId}`;
  return axios.get(url);
}

export function getDevices(operatorId: number, deviceId?: number) {
  let url = `/admin/operators/${operatorId}/sign/cms/devices?once=${Date.now()}`;
  if (deviceId) url += `/${deviceId}`;
  return axios.get(url);
}

export function getIcons(operatorId: number) {
  return axios.get(`/admin/operators/${operatorId}/sign/icons`);
}

export function getLayouts(operatorId: number, layoutId?: number) {
  let url = `/admin/operators/${operatorId}/sign/cms/layouts`;
  if (layoutId) url += `/${layoutId}`;
  return axios.get(url);
}

export function getLayoutTemplates(operatorId: number) {
  return axios.get(`/admin/operators/${operatorId}/sign/cms/layouts/tmp`);
}

export function getMedia(operatorId: number, mediaId?: number) {
  let url = `/admin/operators/${operatorId}/sign/cms/media`;
  if (mediaId) url += `/${mediaId}`;
  return axios.get(url);
}

export function getAllMedia(operatorId: number, mediaId?: number) {
  let url = `/admin/operators/${operatorId}/sign/cms/media`;
  if (mediaId) url += `/${mediaId}`;
  url += '?incldel=t';
  return axios.get(url);
}

export function getOperators() {
  return axios.get(`/admin/operators?lang=th`);
}

export function getPlaylists(operatorId: number) {
  return axios.get(`/admin/operators/${operatorId}/sign/cms/playlists`);
}

export function getScreens(operatorId: number) {
  return axios.get(`/admin/operators/${operatorId}/sign/cms/screens`);
}

export function getSounds(operatorId: number) {
  return axios.get(`/admin/operators/${operatorId}/sign/sounds`);
}

export function getSoundUsage(operatorId: number, soundIds: any) {
  return axios.post(`/admin/operators/${operatorId}/sign/sounds/usage`, { data: soundIds });
}

export function getVehicles(operatorId: number) {
  return axios.get(`/admin/operators/${operatorId}/vehicles`);
}

export function getVehicle(operatorId: number, vhcId: number) {
  return axios.get(`/admin/operators/${operatorId}/vehicles/${vhcId}`);
}

export function getSoundGroups(operatorId: number) {
  return axios.get(`/admin/operators/${operatorId}/sign/sounds/groups`);
}

export function getMediaStats(operatorId: number, start: string, end: string) {
  let tz: string = encodeURIComponent('+07:00');
  return axios.get(
    `/admin/operators/${operatorId}/sign/contents/stats/media?start=${start}&end=${end}&tz=${tz}`
  );
}

export function getWebStats(operatorId: number, start: string, end: string) {
  let tz: string = encodeURIComponent('+07:00');
  return axios.get(
    `/admin/operators/${operatorId}/sign/contents/stats/web?start=${start}&end=${end}&tz=${tz}`
  );
}

export function getUser(operatorId: number, usr: string | null) {
  return axios.get(`/admin/operators/${operatorId}/users/${usr}`);
}

export function getSystems(operatorId: number) {
  return axios.get(`/admin/operators/${operatorId}/systems?lang=th`);
}

export function getNetworks(operatorId: number, systemId: number) {
  return axios.get(`/admin/operators/${operatorId}/systems/${systemId}/networks?lang=th`);
}

export function getNetworkVersions(operatorId: number, network: any) {
  return axios.get(
    `/admin/operators/${operatorId}/systems/${network.sys_id}/layers/${network.lyr_id}/networks/${network.net_id}/versions`
  );
}

export function getNetworkCurrentVersion(operatorId: number, network: any) {
  return axios.get(
    `/admin/operators/${operatorId}/systems/${network.sys_id}/layers/${network.lyr_id}/networks/${network.net_id}/versions/current`
  );
}

export function getNodes(operatorId: number, network: any) {
  return axios.get(
    `/admin/operators/${operatorId}/systems/${network.sys_id}/layers/${network.lyr_id}/nodes?lang=th`
  );
}

export function getNetworkArcs(operatorId: number, network: any, version: number) {
  return axios.get(
    `/admin/operators/${operatorId}/systems/${network.sys_id}/layers/${network.lyr_id}/networks/${network.net_id}/versions/${version}/nodes`
  );
}

export function getNetworkArcSounds(operatorId: number, network: any, version: number) {
  return axios.get(
    `/admin/operators/${operatorId}/sign/systems/${network.sys_id}/layers/${network.lyr_id}/networks/${network.net_id}/versions/${version}/arcs`
  );
}

export function postContentGroup(operatorId: number, data: any) {
  return axios.post(`/admin/operators/${operatorId}/sign/cms/content`, data);
}

export function postIcon(operatorId: number, data: any) {
  return axios.post(`/admin/operators/${operatorId}/sign/icons`, data);
}

export function postLayout(operatorId: number, data: any) {
  return axios.post(`/admin/operators/${operatorId}/sign/cms/layouts`, data);
}

export function postReplaceLayout(operatorId: number, layoutId: number, data: any) {
  return axios.post(`/admin/operators/${operatorId}/sign/cms/layouts/${layoutId}/replaced`, data);
}

export function postMedia(operatorId: number, data: any) {
  return axios.post(`/admin/operators/${operatorId}/sign/cms/media`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

export function postPlaylist(operatorId: number, data: any) {
  return axios.post(`/admin/operators/${operatorId}/sign/cms/playlists`, data);
}

export function postScreen(operatorId: number, data: any) {
  return axios.post(`/admin/operators/${operatorId}/sign/cms/screens`, data);
}

export function postSound(operatorId: number, data: any, config: any) {
  return axios.post(`/admin/operators/${operatorId}/sign/sounds`, data, config);
}

export function postSoundGroup(operatorId: number, data: any) {
  return axios.post(`/admin/operators/${operatorId}/sign/sounds/groups`, data);
}

export function linkDeviceWithOperator(operatorId: number, deviceLinkKey: string, name: string) {
  return axios.post(`/admin/operators/${operatorId}/sign/cms/devices/link?key=${deviceLinkKey}`, {
    name,
  });
}

export function putDeviceUserConfig(operatorId: number, userId: number, data: any) {
  return axios.put(`/admin/operators/${operatorId}/sign/cms/devices/${userId}`, data);
}

export function putPlaylist(operatorId: number, playlistId: number, data: any) {
  return axios.put(`/admin/operators/${operatorId}/sign/cms/playlists/${playlistId}`, data);
}

export function putScreen(operatorId: number, screenId: number, data: any) {
  return axios.put(`/admin/operators/${operatorId}/sign/cms/screens/${screenId}`, data);
}

export function putSoundGroup(operatorId: number, soundGroupId: number, data: any) {
  return axios.put(`/admin/operators/${operatorId}/sign/sounds/groups/${soundGroupId}`, data);
}

export function linkDeviceWithScreen(operatorId: number, deviceScreenArray: any) {
  return axios.post(`/admin/operators/${operatorId}/sign/cms/devices`, deviceScreenArray); // eslint-disable-line
}

export function putNetworkArcSounds(operatorId: number, network: any, version: number, data: any) {
  return axios.put(
    `/admin/operators/${operatorId}/sign/systems/${network.sys_id}/layers/${network.lyr_id}/networks/${network.net_id}/versions/${version}/arcs`,
    data
  );
}

export function putContentGroup(operatorId: number, contentGroupId: number, data: any) {
  return axios.put(`/admin/operators/${operatorId}/sign/cms/content/${contentGroupId}`, data);
}

export function linkDeviceWithVehicle(
  operatorId: number,
  deviceVehicleArray: any,
  vehicleOpId: number
) {
  return axios.post(
    `/admin/operators/${operatorId}/sign/cms/devices/link/operators/${vehicleOpId}/vehicles`,
    deviceVehicleArray
  ); // eslint-disable-line
}

// Smart Plug
export function getOperatorLocale(operatorId: number) {
  return axios.get(`/admin/operators/${operatorId}/commons/locales`);
}

export function getSmartPlugPlatforms(operatorId: number) {
  return axios.get(`/admin/operators/${operatorId}/devices/iot/platforms`);
}

export function getSmartPlugPlatformGroups(operatorId: number, platformId: number) {
  return axios.get(`/admin/operators/${operatorId}/devices/iot/platforms/${platformId}/groups`);
}

export function getSmartPlugSubGroups(operatorId: number, platformId: number, groupId: number) {
  return axios.get(
    `/admin/operators/${operatorId}/devices/iot/platforms/${platformId}/groups/${groupId}/subgroups`
  );
}

export function getSmartPlugDevices(operatorId: number, platformId: number, groupId: number) {
  return axios.get(
    `/admin/operators/${operatorId}/devices/iot/platforms/${platformId}/groups/${groupId}/devices?limit=1000`
  );
}

export function getSmartPlugUnbindedDevices(
  operatorId: number,
  platformId: number,
  groupId: number
) {
  return axios.get(
    `/admin/operators/${operatorId}/devices/iot/platforms/${platformId}/groups/${groupId}/devices/unbinded`
  );
}

export function removeSmartPlugDevice(
  operatorId: number,
  platformId: number,
  groupId: number,
  deviceId: number
) {
  return axios.delete(
    `/admin/operators/${operatorId}/devices/iot/platforms/${platformId}/groups/${groupId}/devices/${deviceId}`
  );
}

export function addSmartPlugDevices(
  operatorId: number,
  platformId: number,
  groupId: number,
  plt_uuids: string[]
) {
  return axios.post(
    `/admin/operators/${operatorId}/devices/iot/platforms/${platformId}/groups/${groupId}/devices/multiple`,
    { plt_uuids, is_act: true }
  );
}

export function getSmartPlugDeviceSchedules(
  operatorId: number,
  platformId: number,
  groupId: number,
  deviceId: number,
  portId: number
) {
  return axios.get(
    `/admin/operators/${operatorId}/devices/iot/platforms/${platformId}/groups/${groupId}/devices/${deviceId}/ports/${portId}/schedules`
  );
}

export function createSmartPlugDeviceSchedule(
  operatorId: number,
  platformId: number,
  groupId: number,
  deviceId: number,
  portId: number,
  schedule: any
) {
  return axios.post(
    `/admin/operators/${operatorId}/devices/iot/platforms/${platformId}/groups/${groupId}/devices/${deviceId}/ports/${portId}/schedules`,
    schedule
  );
}

export function updateSmartPlugDeviceSchedule(
  operatorId: number,
  platformId: number,
  groupId: number,
  deviceId: number,
  portId: number,
  scheduleId: number,
  schedule: any
) {
  return axios.put(
    `/admin/operators/${operatorId}/devices/iot/platforms/${platformId}/groups/${groupId}/devices/${deviceId}/ports/${portId}/schedules/${scheduleId}`,
    schedule
  );
}

export function deleteSmartPlugDeviceSchedule(
  operatorId: number,
  platformId: number,
  groupId: number,
  deviceId: number,
  portId: number,
  scheduleId: number
) {
  return axios.delete(
    `/admin/operators/${operatorId}/devices/iot/platforms/${platformId}/groups/${groupId}/devices/${deviceId}/ports/${portId}/schedules/${scheduleId}`
  );
}

export function activateSmartPlugDeviceSchedule(
  operatorId: number,
  platformId: number,
  groupId: number,
  deviceId: number,
  portId: number,
  scheduleId: number
) {
  return axios.put(
    `/admin/operators/${operatorId}/devices/iot/platforms/${platformId}/groups/${groupId}/devices/${deviceId}/ports/${portId}/schedules/${scheduleId}/activate`
  );
}

export function deactivateSmartPlugDeviceSchedule(
  operatorId: number,
  platformId: number,
  groupId: number,
  deviceId: number,
  portId: number,
  scheduleId: number
) {
  return axios.put(
    `/admin/operators/${operatorId}/devices/iot/platforms/${platformId}/groups/${groupId}/devices/${deviceId}/ports/${portId}/schedules/${scheduleId}/deactivate`
  );
}

export function getSmartPlugGlobalSchedules(
  operatorId: number,
  platformId: number,
  groupId: number,
  subGroupId: number
) {
  return axios.get(
    `/admin/operators/${operatorId}/devices/iot/platforms/${platformId}/groups/${groupId}/subgroups/${subGroupId}/schedules`
  );
}

export function createSmartPlugGlobalSchedule(
  operatorId: number,
  platformId: number,
  groupId: number,
  schedule: any
) {
  return axios.post(
    `/admin/operators/${operatorId}/devices/iot/platforms/${platformId}/groups/${groupId}/subgroups`,
    schedule
  );
}

export function updateSmartPlugGlobalSchedule(
  operatorId: number,
  platformId: number,
  groupId: number,
  subGroupId: number,
  scheduleId: number,
  schedule: any
) {
  return axios.put(
    `/admin/operators/${operatorId}/devices/iot/platforms/${platformId}/groups/${groupId}/subgroups/${subGroupId}/schedules/${scheduleId}`,
    schedule
  );
}

export function updateSmartPlugGlobalScheduleDevices(
  operatorId: number,
  platformId: number,
  groupId: number,
  subGroupId: number,
  deviceIds: number[]
) {
  return axios.put(
    `/admin/operators/${operatorId}/devices/iot/platforms/${platformId}/groups/${groupId}/subgroups/${subGroupId}`,
    { dvc_ids: deviceIds }
  );
}

export function deleteSmartPlugGlobalSchedule(
  operatorId: number,
  platformId: number,
  groupId: number,
  subGroupId: number
) {
  return axios.delete(
    `/admin/operators/${operatorId}/devices/iot/platforms/${platformId}/groups/${groupId}/subgroups/${subGroupId}`
  );
}

export function activateSmartPlugGlobalSchedule(
  operatorId: number,
  platformId: number,
  groupId: number,
  subGroupId: number,
  scheduleId: number
) {
  return axios.put(
    `/admin/operators/${operatorId}/devices/iot/platforms/${platformId}/groups/${groupId}/subgroups/${subGroupId}/schedules/${scheduleId}/activate`
  );
}

export function deactivateSmartPlugGlobalSchedule(
  operatorId: number,
  platformId: number,
  groupId: number,
  subGroupId: number,
  scheduleId: number
) {
  return axios.put(
    `/admin/operators/${operatorId}/devices/iot/platforms/${platformId}/groups/${groupId}/subgroups/${subGroupId}/schedules/${scheduleId}/deactivate`
  );
}

export function unforceAllSmartPlugDevices(
  operatorId: number,
  platformId: number,
  groupId: number
) {
  return axios.put(
    `/admin/operators/${operatorId}/devices/iot/platforms/${platformId}/groups/${groupId}/devices/unforce`
  );
}

export function forceAllSmartPlugDevices(
  operatorId: number,
  platformId: number,
  groupId: number,
  prtState: boolean
) {
  return axios.put(
    `/admin/operators/${operatorId}/devices/iot/platforms/${platformId}/groups/${groupId}/devices/force`,
    { prt_state: prtState }
  );
}

export function unforceSmartPlugDevice(
  operatorId: number,
  platformId: number,
  groupId: number,
  deviceId: number
) {
  return axios.put(
    `/admin/operators/${operatorId}/devices/iot/platforms/${platformId}/groups/${groupId}/devices/${deviceId}/unforce`
  );
}

export function forceSmartPlugDevice(
  operatorId: number,
  platformId: number,
  groupId: number,
  deviceId: number,
  prtState: boolean
) {
  return axios.put(
    `/admin/operators/${operatorId}/devices/iot/platforms/${platformId}/groups/${groupId}/devices/${deviceId}/force`,
    { prt_state: prtState }
  );
}

export function linkSmartPlugWithAndroidBoxIds(
  operatorId: number,
  platformId: number,
  groupId: number,
  deviceId: number,
  portId: number,
  androidBoxIds: any
) {
  return axios.put(
    `/admin/operators/${operatorId}/devices/iot/platforms/${platformId}/groups/${groupId}/devices/${deviceId}/ports/${portId}/signs`,
    { usr_ids: androidBoxIds }
  );
}

export function linkSmartPlugWithSmartPlugs(
  operatorId: number,
  platformId: number,
  groupId: number,
  deviceId: number,
  portId: number,
  devices: any
) {
  return axios.put(
    `/admin/operators/${operatorId}/devices/iot/platforms/${platformId}/groups/${groupId}/devices/${deviceId}/ports/${portId}/peripherals/operators`,
    devices
  );
}

export function updateSmartPlugDevice(
  operatorId: number,
  platformId: number,
  groupId: number,
  deviceId: number,
  device: any
) {
  return axios.put(
    `/admin/operators/${operatorId}/devices/iot/platforms/${platformId}/groups/${groupId}/devices/${deviceId}`,
    device
  );
}

export function activateSmartPlugLineBotOTP(
  operatorId: number,
  platformId: number,
  groupId: number,
  otp: string
) {
  return axios.post(
    `/admin/operators/${operatorId}/devices/iot/platforms/${platformId}/groups/${groupId}/notify/1/chatrooms`,
    { bot_id: 1, otp: otp, is_act: true }
  );
  // return axios.get(
  //   `/admin/operators/${operatorId}/devices/iot/notify/bots/1/chatrooms/otp?otp=${otp}&act=t`
  // );
}

export function bindService(operatorId: number, platformId: number, groupId: number) {
  return axios.post(
    `/admin/operators/${operatorId}/devices/iot/platforms/${platformId}/groups/${groupId}/notify/1/chatrooms`
  );
}

export function updateSmartPlugLineBotSettings(
  operatorId: number,
  platformId: number,
  groupId: number,
  settings: any
) {
  return axios.put(
    `/admin/operators/${operatorId}/devices/iot/platforms/${platformId}/groups/${groupId}/notify/1`,
    settings
  );
}

export function getSmartPlugLineBotSettings(
  operatorId: number,
  platformId: number,
  groupId: number
) {
  return axios.get(
    `/admin/operators/${operatorId}/devices/iot/platforms/${platformId}/groups/${groupId}/notify`
  );
}

export function getSmartPlugLineBotChatRooms(
  operatorId: number,
  platformId: number,
  groupId: number
) {
  return axios.get(
    `/admin/operators/${operatorId}/devices/iot/platforms/${platformId}/groups/${groupId}/notify`
  );
}

export function activateSmartPlugLineBotChatRoom(
  operatorId: number,
  platformId: number,
  groupId: number,
  chatRoomId: number
) {
  return axios.put(
    `/admin/operators/${operatorId}/devices/iot/platforms/${platformId}/groups/${groupId}/notify/1/chatrooms/bots/1/chatrooms/${chatRoomId}/activate`
  );
}

export function deactivateSmartPlugLineBotChatRoom(
  operatorId: number,
  platformId: number,
  groupId: number,
  chatRoomId: number
) {
  return axios.put(
    `/admin/operators/${operatorId}/devices/iot/platforms/${platformId}/groups/${groupId}/notify/1/chatrooms/bots/1/chatrooms/${chatRoomId}/deactivate`
  );
}

// Announcement
export function getAnnouncement(operatorId: number) {
  let url = `/admin/operators/${operatorId}/sign/screen-tickers/messages`;
  return axios.get(url);
}

export function createAnnouncement(operatorId: number, msg: string) {
  return axios.post(`/admin/operators/${operatorId}/sign/screen-tickers/messages`, {
    is_act: true,
    messages: [
      {
        lang: 'th',
        msg: msg,
      },
    ],
  });
}

export function updateAnnouncement(operatorId: number, msgId: string, msg: string) {
  return axios.put(`/admin/operators/${operatorId}/sign/screen-tickers/messages/${msgId}`, {
    is_act: true,
    messages: [
      {
        lang: 'th',
        msg: msg,
      },
    ],
  });
}
