<template>
  <div class="dropdown-menu">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'DropDownMenu',
};
</script>

<style scoped>
.dropdown-menu {
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  box-sizing: border-box;
  box-shadow: 0 0 4px 0 #dbe0f0;
  max-width: 30vw;
}

.dropdown-menu > :first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.dropdown-menu > :last-child {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
</style>
