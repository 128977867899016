<template>
  <div class="operator-menu-container">
    <div class="operator-menu" @click="$emit('click')">
      <template>
        <div class="operator" v-if="operator">
          {{ operator }}
        </div>
        <div class="system label-500 label-slate60" v-if="system">
          {{ system }}
        </div>
        <Chevron class="chevron" v-if="operator || system" />
      </template>
    </div>
    <slot />
  </div>
</template>

<script>
import Chevron from '@/components/icons/Chevron.vue';

export default {
  name: 'OperatorMenu',
  props: {
    operator: String,
    system: String,
  },
  components: {
    Chevron,
  },
};
</script>

<style scoped>
.operator-menu-container {
  position: relative;
}

.operator-menu {
  display: flex;
  font-size: 18px;
  font-weight: 600;
  padding-top: 13px;
  padding-bottom: 13px;
  padding-left: 26px;
  user-select: none;
  cursor: pointer;
}
.operator {
  color: #202d60;
}
.system {
  margin-left: 24px;
}
.chevron {
  margin-left: 2px;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
}
</style>
