import Vue from 'vue';
import Vuex from 'vuex';

import _, { find, map } from 'lodash';

import get from 'lodash/get';

import {
  getDevices,
  getLayouts,
  getLayoutTemplates,
  getMedia,
  getPlaylists,
  getScreens,
  getVehicles,
  getOperators,
  getSystems,
  getContentGroups,
  getSoundGroups,
  getSounds,
  getUser,
  getAllMedia,
  getNetworks,
} from '@/services/axios';

import { OPERATOR_ID, SYSTEM_ID, CURRENT_TOKEN, SHOW_NO_LOAD_MODE_HINT } from '@/services/config';
import { Media, Playlist, PlaylistMedia } from '@/types/index.d';

Vue.use(Vuex);

// * See https://github.com/ktsn/vuex-class for using Vuex with typescript

export default new Vuex.Store({
  state: {
    deviceKey: null,
    contentGroups: [],
    devices: [],
    layouts: [],
    layoutTemplates: [],
    media: [],
    opId: Number(localStorage.getItem(OPERATOR_ID)) || 0,
    sysId: Number(localStorage.getItem(SYSTEM_ID)) || 0,
    operators: [],
    systems: [],
    currentSystem: null,
    playlists: [],
    screens: [],
    vehicles: [],
    soundGroups: [],
    sounds: [],
    userId: null,
    user: null,
    allMedia: [],
    networks: [],
    systemsOfAllOperators: {},
    showNoLoadingModeHint: JSON.parse(localStorage.getItem(SHOW_NO_LOAD_MODE_HINT) || 'true'),
  },
  getters: {
    currentOperator(state) {
      return find(state.operators, operator => operator['op_id'] === state.opId);
    },
    noLoadingModeAvailable(state, getters) {
      // no loading mode flag = 2 (1 << 1)
      return getters.currentOperator.op_flag && 1 << 1 == 1 << 1;
    },
  },
  mutations: {
    setDeviceKey(state, key) {
      state.deviceKey = key;
    },
    setContentGroups(state, contentGroups) {
      state.contentGroups = contentGroups;
    },
    setDevices(state, devices) {
      state.devices = devices;
    },
    setLayouts(state, layouts) {
      state.layouts = layouts;
    },
    setLayoutTemplates(state, layoutTemplates) {
      state.layoutTemplates = layoutTemplates;
    },
    setMedia(state, media) {
      state.media = media;
    },
    setOperators(state, operators) {
      state.operators = operators;
    },
    setSystems(state, systems) {
      state.systems = systems;
    },
    setCurrentSystem(state, system) {
      state.currentSystem = system;
    },
    setOpId(state, opId) {
      state.opId = opId;
    },
    setPlaylists(state, playlists) {
      state.playlists = playlists;
    },
    setScreens(state, screens) {
      state.screens = screens;
    },
    setVehicles(state, vehicles) {
      state.vehicles = vehicles;
    },
    setSoundGroups(state, soundGroups) {
      state.soundGroups = soundGroups;
    },
    setSounds(state, sounds) {
      state.sounds = sounds;
    },
    setUserId(state, userId) {
      state.userId = userId;
    },
    setUser(state, user) {
      state.user = user;
    },
    setAllMedia(state, media) {
      state.allMedia = media;
    },
    setNetworks(state, networks) {
      state.networks = networks;
    },
    setSystemsOfAllOperators(state, systems) {
      state.systemsOfAllOperators = systems;
    },
    setShowNoLoadingModeHint(state, showNoLoadingModeHint: boolean) {
      localStorage.setItem(SHOW_NO_LOAD_MODE_HINT, showNoLoadingModeHint.toString());
      state.showNoLoadingModeHint = showNoLoadingModeHint;
    },
  },
  actions: {
    loadData({ dispatch }) {
      if (!this.state.opId || !localStorage.getItem(CURRENT_TOKEN)) return;
      setTimeout(() => {
        dispatch('loadContentGroups');
        dispatch('loadDevices');
        dispatch('loadLayouts');
        dispatch('loadLayoutTemplates');
      }, 0);

      setTimeout(() => {
        // dispatch('loadMedia');
        dispatch('loadMediaThenPlaylists');
        dispatch('loadScreens');
        dispatch('loadVehicles');
      }, 1200);

      setTimeout(() => {
        dispatch('loadSoundGroups');
        dispatch('loadSounds');
        dispatch('loadOperators');
        dispatch('loadSystems');
        dispatch('loadUser');
        dispatch('loadAllMedia');
      }, 2400);
    },
    loadContentGroups({ commit }) {
      return getContentGroups(this.state.opId).then(res => {
        commit('setContentGroups', res.data);
        return res;
      });
    },
    loadDevices({ commit }) {
      return getDevices(this.state.opId).then(res => {
        commit('setDevices', res.data);
        return res;
      });
    },
    loadLayouts({ commit }) {
      return getLayouts(this.state.opId).then(res => {
        commit('setLayouts', res.data);
        return res;
      });
    },
    loadLayoutTemplates({ commit }) {
      return getLayoutTemplates(this.state.opId).then(res => {
        commit('setLayoutTemplates', res.data);
        return res;
      });
    },
    loadMedia({ commit }) {
      return getMedia(this.state.opId).then(res => {
        commit('setMedia', res.data);
        return res;
      });
    },
    loadOperators({ commit }) {
      return getOperators().then(res => {
        // commit(
        //   'setOperators',
        //   res.data.filter((op: any) => op.op_id === 54)
        // );

        commit('setOperators', res.data);
        return res;
      });
    },
    loadSystems({ commit }) {
      return getSystems(this.state.opId).then(res => {
        commit('setSystems', res.data);
        if (!this.state.currentSystem && res.data.length > 0) {
          const system = this.state.sysId
            ? find(res.data, (o: any) => o.sys_id === this.state.sysId)
            : res.data[0];
          commit('setCurrentSystem', system);
        }
        return res;
      });
    },
    loadMediaThenPlaylists({ commit }) {
      return getMedia(this.state.opId)
        .then(res => {
          commit('setMedia', res.data);
          return res;
        })
        .then(res => {
          getPlaylists(this.state.opId).then(res => {
            const playlists: Playlist[] = map(res.data, playlist => {
              const mediaList: PlaylistMedia[] = playlist.media;
              mediaList.forEach((playlistMedia: PlaylistMedia) => {
                const media: Media | undefined = find(
                  this.state.media,
                  (media: Media) =>
                    media.op_id === playlist.op_id && media.m_id === playlistMedia.m_id
                );
                playlistMedia['video_duration'] =
                  media !== undefined ? media.length : playlistMedia.duration;
              });
              return playlist;
            });
            commit('setPlaylists', playlists);
            return res;
          });
        });
    },
    loadPlaylists({ commit }) {
      return getPlaylists(this.state.opId).then(res => {
        const playlists: Playlist[] = map(res.data, playlist => {
          const mediaList: PlaylistMedia[] = playlist.media;
          mediaList.forEach((playlistMedia: PlaylistMedia) => {
            const media: Media | undefined = find(
              this.state.media,
              (media: Media) => media.op_id === playlist.op_id && media.m_id === playlistMedia.m_id
            );
            playlistMedia['video_duration'] =
              media !== undefined ? media.length : playlistMedia.duration;
          });
          return playlist;
        });
        commit('setPlaylists', playlists);
        return res;
      });
    },
    loadScreens({ commit }) {
      return getScreens(this.state.opId).then(res => {
        commit('setScreens', res.data);
        return res;
      });
    },
    loadVehicles({ commit }) {
      return getVehicles(this.state.opId).then(res => {
        commit('setVehicles', res.data);
        return res;
      });
    },
    loadSoundGroups({ commit }) {
      return getSoundGroups(this.state.opId).then(res => {
        commit('setSoundGroups', res.data);
        return res;
      });
    },
    loadSounds({ commit }) {
      return getSounds(this.state.opId).then(res => {
        commit('setSounds', res.data);
        return res;
      });
    },
    loadAllMedia({ commit }) {
      return getAllMedia(this.state.opId).then(res => {
        commit('setAllMedia', res.data);
        return res;
      });
    },
    loadUser({ commit }) {
      return getUser(this.state.opId, this.state.userId).then(res => {
        commit('setUser', res.data);
        return res;
      });
    },
    loadNetworks({ commit }) {
      return getNetworks(this.state.opId, get(this.state.currentSystem, 'sys_id', -1)).then(res => {
        commit('setNetworks', res.data);
        return res;
      });
    },
  },
  modules: {},
});
