export const URL: string = process.env.VUE_APP_API_URL;

export const AUTH_URL: string = URL;

export const CURRENT_TOKEN: string = 'ViaSignCmsCurrentToken';
export const REFRESH_TOKEN: string = 'ViaSignCmsRefreshToken';
export const USR: string = 'usr';

export const GOOGLE_MAPS_TOKEN: string = process.env.VUE_APP_GOOGLE_MAPS_API_KEY;

export const VERSION: string = process.env.VUE_APP_VERSION;

export const OPERATOR_ID: string = 'opid';
export const SYSTEM_ID: string = 'sysid';
export const SHOW_NO_LOAD_MODE_HINT: string = 'show_no_loading_mode_hint';
