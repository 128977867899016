var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('section',{attrs:{"id":"app"}},[(_vm.$route.name === 'login-view' || _vm.$route.name === 'devices-link-view')?_c('section',[_c('router-view')],1):_c('section',{style:(_vm.$route.name === 'sound-editor' ? 'overflow: hidden;' : ''),attrs:{"id":"logged-in-view"}},[_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.resetMenus),expression:"resetMenus"}],attrs:{"id":"navbar"}},[_c('div',{staticStyle:{"display":"flex","align-items":"center","height":"100%"}},[_c('img',{staticStyle:{"margin":"0 25px"},attrs:{"src":require("@/assets/icons/via-sign.svg")}}),_c('OperatorMenu',{attrs:{"operator":_vm.operatorName,"system":_vm.currentSystemName},on:{"click":function($event){_vm.systemsMenuVisible = !_vm.systemsMenuVisible}}},[_c('DropdownMenu',{directives:[{name:"show",rawName:"v-show",value:(_vm.systemsMenuVisible),expression:"systemsMenuVisible"}]},[_c('LeftScrollbar',{attrs:{"height":"50vh"}},[_c('DropdownMenuItem',{attrs:{"header":""}},[_vm._v(" Operator ")]),_vm._l((_vm.operators),function(operator){return [_c('DropdownMenuItem',{key:operator.op_id,on:{"click":function($event){return _vm.selected(operator)}}},[_c('template',{slot:"icon"},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.opId === operator.op_id),expression:"opId === operator.op_id"}]},[_c('Tick')],1)]),_c('div',[_vm._v(_vm._s(operator.i18n_name || operator.dflt_name))]),_c('template',{slot:"submenu"},[(
                        typeof _vm.systems[operator.op_id] === 'object' &&
                          Object.keys(_vm.systems[operator.op_id]).length > 0
                      )?_c('DropdownMenu',_vm._l((_vm.systems[operator.op_id]),function(system){return _c('DropdownMenuItem',{key:system.sys_id,on:{"click":function($event){return _vm.selected(operator, system)}}},[_c('template',{slot:"icon"},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(
                              _vm.opId === operator.op_id &&
                                _vm.currentSystem &&
                                system &&
                                _vm.currentSystem.sys_id === Number(system.sys_id)
                            ),expression:"\n                              opId === operator.op_id &&\n                                currentSystem &&\n                                system &&\n                                currentSystem.sys_id === Number(system.sys_id)\n                            "}]},[_c('Tick')],1)]),_c('div',[_vm._v(_vm._s(system.i18n_name || system.dflt_name))])],2)}),1):_vm._e()],1)],2)]})],2)],1)],1),(_vm.isUserActive)?_c('div',{staticStyle:{"display":"flex","align-items":"center","margin-left":"auto"}},[_c('div',{staticClass:"version"},[_vm._v(_vm._s(_vm.version))]),_c('img',{staticStyle:{"margin-right":"8px"},attrs:{"src":require("@/assets/icons/res-person.svg")}}),_c('div',{staticClass:"user-display-name"},[_vm._v(_vm._s(_vm.userDisplayName))]),_c('img',{staticClass:"clickable",staticStyle:{"margin-right":"24px"},attrs:{"src":require("@/assets/icons/ic-chev-s-denim.svg")},on:{"click":function($event){_vm.logoutMenuVisible = !_vm.logoutMenuVisible}}})]):_vm._e()],1),(_vm.logoutMenuVisible)?_c('div',{staticClass:"logout",on:{"click":function($event){return _vm.goToLogin()}}},[_vm._v("Logout")]):_vm._e(),(!_vm.isUserActive)?_c('div',{staticClass:"login",on:{"click":function($event){return _vm.goToLogin()}}},[_vm._v("Login")]):_vm._e()]),(_vm.$route.name !== 'sound-editor')?_c('Sidebar',{attrs:{"defaultRouteName":_vm.routeName,"id":"sidebar"}}):_vm._e(),(_vm.$route.name !== 'sound-editor')?_c('div',{attrs:{"id":"main-panel"}},[_c('router-view')],1):(_vm.$route.name === 'sound-editor')?_c('div',[_c('router-view')],1):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }