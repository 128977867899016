import Vue from 'vue';

// @ts-ignore
import vClickOutside from 'v-click-outside';
// @ts-ignore
import VueDraggableResizable from 'vue-draggable-resizable';
// @ts-ignore
import VueTextareaAutosize from 'vue-textarea-autosize';

import Toasted from 'vue-toasted';

import VTooltip from 'v-tooltip';
import VCalendar from 'v-calendar';

import App from './App.vue';
import router from './router';
import store from './store';

import './components';

import 'leaflet/dist/leaflet.css';

import 'vue-draggable-resizable/dist/VueDraggableResizable.css';

Vue.use(vClickOutside);
Vue.component('vue-draggable-resizable', VueDraggableResizable);

Vue.config.productionTip = false;

Vue.use(VCalendar, {});
Vue.use(VTooltip, {
  defaultBoundariesElement: document.body,
});

Vue.use(Toasted, {
  position: 'top-center',
  singleton: true,
  duration: 3000,
});

Vue.use(VueTextareaAutosize);

new Vue({
  router,
  store,
  render: h => h(App),
  watch: {
    $route: function(to, from) {},
  },
}).$mount('#app');
