<template>
  <Icon :src="arrowEastSrc" />
</template>

<script>
import Icon from '@/components/icons/Icon.vue';
import IcArrowEast from '@/assets/icons/ic-arrow-e.svg';

export default {
  name: 'ArrowEast',
  components: {
    Icon,
  },
  computed: {
    arrowEastSrc: () => IcArrowEast,
  },
};
</script>
