export default [
  {
    text: 'กล่องสัญญาณ',
    routeName: 'devices',
  },
  {
    text: 'สมาร์ทปลั๊ก',
    routeName: 'smartplug',
    subMenus: [
      {
        text: 'สถานะ',
        routeName: 'smartplug',
      },
      // {
      //   text: 'ประวัติ',
      //   routeName: 'smartplug-logs',
      // },
    ],
  },
  {
    text: 'หน้าจอ',
    routeName: 'screens',
  },
  {
    text: 'เลย์เอาต์',
    routeName: 'layouts',
  },
  {
    text: 'เพลย์ลิสต์',
    routeName: 'playlists',
  },
  {
    text: 'สื่อ',
    routeName: 'media',
  },
  {
    text: 'สถิติ',
    routeName: 'stats',
  },
  {
    text: 'ประกาศ',
    routeName: 'announcement',
  },
];
