<template>
  <Icon :src="tickSrc" />
</template>

<script>
import Icon from '@/components/icons/Icon.vue';
import IcTickBright from '@/assets/icons/tick/ic-tick-bright.svg';

export default {
  name: 'Tick',
  components: {
    Icon,
  },
  computed: {
    tickSrc: () => IcTickBright,
  },
};
</script>
