<template>
  <div
    class="dropdown-menu-item-container"
    @mouseover="handleMouseover"
    @mouseleave="handleMouseleave"
  >
    <div
      class="dropdown-menu-item"
      :class="{ header, active: isSubmenuVisible }"
      @click="$emit('click')"
    >
      <div class="icon" v-if="$slots.icon">
        <slot name="icon" />
      </div>
      <div class="text">
        <slot />
      </div>
      <div class="icon arrow" v-if="$slots.submenu">
        <slot name="arrow-icon" v-if="$slots['arrow-icon']" />
        <ArrowEast v-else />
      </div>
    </div>
    <div class="submenu" v-show="isSubmenuVisible">
      <slot name="submenu" />
    </div>
  </div>
</template>

<script>
import ArrowEast from '@/components/icons/ArrowEast.vue';

export default {
  name: 'DropDownMenuItem',
  components: {
    ArrowEast,
  },
  props: {
    header: Boolean,
  },
  data: () => ({
    isSubmenuVisible: false,
  }),
  methods: {
    handleMouseover() {
      this.isSubmenuVisible = true;
    },
    handleMouseleave() {
      this.isSubmenuVisible = false;
    },
  },
};
</script>

<style scoped>
.dropdown-menu-item-container {
  position: relative;
}

.dropdown-menu-item {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  min-width: 216px;
  padding: 12px;
  background-color: #ffffff;
  user-select: none;
}

.dropdown-menu-item:hover,
.dropdown-menu-item.active {
  background-color: #e8edfc;
}

.dropdown-menu-item.header,
.dropdown-menu-item.header:hover {
  padding-top: 12px;
  padding-bottom: 12px;
  background-color: #ffffff;
}

.text {
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  color: var(--denim);
}

.dropdown-menu-item.header > .text {
  font-size: 12px;
  line-height: 12px;
  font-weight: 600;
  color: #9ca6c9;
}

.icon {
  height: 24px;
  width: 24px;
  margin-right: 6px;
}

.arrow {
  margin-left: auto;
}

.submenu {
  position: absolute;
  top: 0;
  left: 100%;
}
</style>
