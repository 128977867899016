
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { State } from 'vuex-class';
import { find, filter, isEmpty, map } from 'lodash';

import SidePanel from '@/components/nav/SidePanel.vue';

import menuItems from '@/data/menu';

@Component({
  components: {
    SidePanel,
  },
})
export default class Sidebar extends Vue {
  @State opId!: number;
  @State user!: any;

  @Prop({ default: 'devices-view' }) readonly defaultRouteName!: string;

  autoSelectMenuRouteNames: string[] = map(this.$router.options.routes, route => route.name!);

  @Watch('defaultRouteName')
  onDefaultRouteNameChanged() {
    this.setDefaultSelectedMenu();
  }

  @Watch('selectedMenu')
  onSelectedMenuChanged() {
    if (this.selectedMenu && this.selectedMenu.subMenus) {
      this.selectedMenu.hideSubMenu = false;
      this.selectedSubMenu = this.selectedMenu.subMenus[0];
    } else {
      this.selectedSubMenu = {};
      this.selectedMenu.hideSubMenu = true;
    }
  }

  @Watch('$route')
  onRouteChanged() {
    const name = this.$route.name;
    if (name) {
      if (this.autoSelectMenuRouteNames.includes(name)) {
        const routeNames = name.split('-');
        if (routeNames && routeNames.length) {
          const routeName = routeNames[0];
          const menu = find(this.menuItems, { routeName });
          if (menu) {
            this.selectMenu(menu, this.$route.query);
          }
        }
      }
    }
  }

  selectedMenu: any = this.menuItems[0];
  selectedSubMenu: any = {};
  hoveringMenu: any = null;

  get menuItems() {
    let menus: any = menuItems.map(i => ({
      ...i,
      // eslint-disable-next-line import/no-dynamic-require
      activeIconPath: require(`@/assets/icons/menu/ic-${i.routeName}-active.svg`),
      // eslint-disable-next-line import/no-dynamic-require
      inactiveIconPath: require(`@/assets/icons/menu/ic-${i.routeName}-inactive.svg`),
      // eslint-disable-next-line import/no-dynamic-require
      hoverIconPath: require(`@/assets/icons/menu/ic-${i.routeName}-hover.svg`),
      hideSubMenu: false,
    }));

    if (this.user) {
      if (this.user.cms_show_full_menu === false) {
        menus = filter(menus, (menu: any) => {
          return menu.routeName === 'devices';
        });
      }
    }

    return menus;
  }

  selectMenu(menu: any, query: any = {}) {
    if (this.selectedMenu.text !== menu.text && isEmpty(menu.subMenus)) {
      this.selectedMenu = menu;

      this.$router.push({
        name: `${menu.routeName}-view`,
        params: {
          opId: `${this.opId}`,
        },
        query: query,
      });
    } else {
      menu.hideSubMenu = !menu.hideSubMenu;
    }
  }

  selectSubMenu(subMenu: any, menu: any) {
    this.selectedSubMenu = subMenu;
    this.selectedMenu = menu;
  }

  setDefaultSelectedMenu() {
    const menu = find(this.menuItems, menu => {
      return `${menu.routeName}-view` === this.defaultRouteName;
    });
    if (menu) {
      this.selectedMenu = menu;
    } else {
      this.selectedMenu = this.menuItems[0];
    }
  }

  getMenuStateClass(menu: any) {
    if (this.selectedMenu.routeName == menu.routeName) {
      if (isEmpty(this.selectedSubMenu)) {
        return 'menu-active';
      } else {
        if (menu.hideSubMenu) {
          return 'menu-collapsed-active';
        }
      }
    }
    return 'menu-inactive';
  }

  getMenuImageSrc(menu: any) {
    if (this.selectedMenu.routeName == menu.routeName) {
      if (isEmpty(this.selectedSubMenu)) {
        return menu.activeIconPath;
      } else {
        if (menu.hideSubMenu) {
          return menu.inactiveIconPath;
        }
      }
    } else {
      if (this.hoveringMenu && this.hoveringMenu.routeName == menu.routeName) {
        return menu.hoverIconPath;
      }
    }
    return menu.inactiveIconPath;
  }

  mounted() {
    this.onRouteChanged();
  }
}
