
// @ts-ignore
import _ from 'lodash-es';

import { Component, Vue, Watch } from 'vue-property-decorator';
import { Action, Mutation, State } from 'vuex-class';
import { AxiosPromise } from 'axios';

import Sidebar from '@/components/nav/Sidebar.vue';
import OperatorMenu from '@/components/nav/OperatorMenu.vue';
import LeftScrollbar from '@/components/LeftScrollbar.vue';
import DropdownMenu from '@/components/nav/DropdownMenu.vue';
import DropdownMenuItem from '@/components/nav/DropdownMenuItem.vue';
import Tick from '@/components/icons/Tick.vue';
import { getSystems } from '@/services/axios';
import {
  CURRENT_TOKEN,
  REFRESH_TOKEN,
  VERSION,
  USR,
  OPERATOR_ID,
  SYSTEM_ID,
} from './services/config';
import router from './router';

@Component({
  components: {
    Sidebar,
    OperatorMenu,
    LeftScrollbar,
    DropdownMenu,
    DropdownMenuItem,
    Tick,
  },
})
export default class App extends Vue {
  @State operators!: any;
  @State user!: any;
  @State userId!: string | null;
  @State currentSystem!: any;
  @State opId!: any;
  @State systemsOfAllOperators!: any;

  @Action loadData!: () => Promise<void>;
  @Action loadOperators!: () => Promise<void>;

  @Mutation setOpId!: (opId: number) => void;
  @Mutation setDeviceKey!: (key: string | null) => void;
  @Mutation setUserId!: (userId: string) => void;
  @Mutation setCurrentSystem!: (system: any) => void;
  @Mutation setSystemsOfAllOperators!: (systems: any) => void;

  routeName: string | null | undefined = null;

  logoutMenuVisible: boolean = false;
  isUserActive: boolean = false;
  currentOperator: any = null;
  systemsMenuVisible: boolean = false;

  @Watch('operators')
  onOperatorsChanged() {
    if (_.isEmpty(this.systemsOfAllOperators)) {
      this.loadSystems(0);
    }
  }

  @Watch('opId', {
    immediate: true,
  })
  onOpIdChanged() {
    if (this.opId && localStorage.getItem(CURRENT_TOKEN)) {
      Promise.all([this.loadData(), this.loadOperators()]).then(() => {
        this.setCurrentOperator();
      });
    }
  }

  @Watch('$route')
  onRouteChanged() {
    this.setStateUserId();
    this.setCurrentOperator();
  }

  get systems() {
    return this.systemsOfAllOperators;
  }

  get currentSystemName() {
    if (this.currentSystem) {
      return this.currentSystem.i18n_name || this.currentSystem.dflt_name || '';
    }
    return null;
  }

  get hasLoggedIn() {
    let hasLoggedIn = ![
      localStorage.getItem(CURRENT_TOKEN),
      localStorage.getItem(REFRESH_TOKEN),
      localStorage.getItem(USR),
    ].includes(null, undefined);

    return hasLoggedIn;
  }

  get operatorName() {
    if (this.currentOperator) {
      return this.currentOperator.i18n_name || this.currentOperator.dflt_name;
    }
    return null;
  }

  get version() {
    return `v${VERSION}`;
  }

  get userDisplayName() {
    if (this.user) {
      return `${this.user.loc_first_name} ${this.user.loc_last_name}`;
    }
    return null;
  }

  loadSystems(operatorIndex: any) {
    let nextOperatorIndex = operatorIndex;
    if (operatorIndex <= this.operators.length - 1) {
      const op_id = this.operators[operatorIndex].op_id;
      getSystems(op_id)
        .then(({ data }) => {
          let systems = this.systemsOfAllOperators;
          systems[op_id] = data;
          this.setSystemsOfAllOperators(systems);
          this.setCurrentOperator();
        })
        .catch(() => {})
        .finally(() => {
          nextOperatorIndex++;
          setTimeout(() => {
            this.loadSystems(nextOperatorIndex);
          }, 200);
        });
    }
  }

  setSystem(system: any) {
    this.setCurrentSystem(system);
    this.systemsMenuVisible = false;
  }

  setStateUserId() {
    let userId = localStorage.getItem(USR);
    if (userId) {
      this.setUserId(userId);
      this.isUserActive = true;
    }
  }

  setCurrentOperator() {
    if (this.operators && this.operators.length > 0) {
      this.currentOperator = _.find(this.operators, (operator: any) => {
        return operator.op_id === this.opId;
      });
    }
  }

  dismissSystemList() {
    this.systemsMenuVisible = false;
  }

  switchOperator(opId: any) {
    this.$router.push({
      query: {
        ...this.$route.query,
      },
      params: {
        ...this.$route.params,
        opId: `${opId}`,
      },
    });
  }

  selected(operator: any, system: any = null) {
    if (!operator) return;

    if (this.opId === operator.op_id && !system) return;
    if (
      this.opId === operator.op_id &&
      system &&
      this.currentSystem &&
      this.currentSystem.sys_id === Number(system.sys_id)
    ) {
      return;
    }

    localStorage.setItem(OPERATOR_ID, operator.op_id);
    localStorage.setItem(SYSTEM_ID, system ? system.sys_id : null);

    this.setOpId(operator.op_id);
    this.setSystem(system);
    this.switchOperator(operator.op_id);
    this.resetMenus();

    window.location.reload();
  }

  resetMenus() {
    this.logoutMenuVisible = false;
    this.systemsMenuVisible = false;
  }

  goToLogin() {
    this.currentOperator = null;
    this.isUserActive = false;
    this.logoutMenuVisible = false;
    localStorage.removeItem(USR);
    location.reload();
  }

  mounted() {
    let deviceKey = new URL(location.href).searchParams.get('key');
    this.setDeviceKey(deviceKey);
    this.setStateUserId();
    if (!this.hasLoggedIn) {
      this.$router.push({
        name: 'login-view',
      });
    }

    this.$router.onReady(() => {
      this.routeName = this.$route.name;

      const opId = Number(this.$route.params.opId || localStorage.getItem('opid') || this.opId);
      if (opId && opId !== this.opId) {
        this.setOpId(opId);
      }

      if (opId) this.loadData();
      else if (this.$route.name !== 'devices-link-view') {
        this.loadOperators().then((res: any) => {
          this.setOpId(res.data[0].op_id);
          this.setStateUserId();

          this.$router.push({
            name: this.$route.name || 'devices-view',
          });
        });
      }
    });
  }
}
