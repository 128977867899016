<template>
  <img class="icon" />
</template>

<script>
export default {
  name: 'Icon',
};
</script>

<style scoped>
.icon {
  min-width: 24px;
  min-height: 24px;
  object-fit: contain;
}
</style>
