import viaAuth from '@via-thailand/viaauth_js';
import { AUTH_URL, CURRENT_TOKEN, REFRESH_TOKEN } from './config';

export const VIA_AUTH = viaAuth({
  apiRoot: AUTH_URL,
});

export function login(username, password) {
  return VIA_AUTH.login(username, password);
}

export function refresh() {
  const VIA_AUTH_REFRESH = viaAuth({
    apiRoot: AUTH_URL,
    currentToken: localStorage.getItem(CURRENT_TOKEN),
    refreshToken: localStorage.getItem(REFRESH_TOKEN),
  });

  return VIA_AUTH_REFRESH.refresh();
}
