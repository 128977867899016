var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.user)?_c('SidePanel',_vm._l((_vm.menuItems),function(item){return _c('div',{key:item.routeName,staticClass:"sidebar_item"},[_c('div',{staticClass:"menu",class:_vm.getMenuStateClass(item),style:({ height: '28px' }),attrs:{"to":{
        name: `${item.routeName}-view`,
        params: {
          opId: _vm.opId,
        },
      }},on:{"click":function($event){return _vm.selectMenu(item)},"mouseenter":function($event){_vm.hoveringMenu = item},"mouseleave":function($event){_vm.hoveringMenu = null}}},[_c('img',{staticStyle:{"width":"16px","margin-left":"20px"},attrs:{"src":_vm.getMenuImageSrc(item)}}),_c('div',{staticClass:"sidebar_item_text label-13"},[_vm._v(" "+_vm._s(item.text)+" ")]),(item.subMenus)?_c('div',{style:({ display: 'flex', position: 'absolute', left: '3px', alignItems: 'center' })},[(item.hideSubMenu)?_c('img',{style:({ width: '16px' }),attrs:{"src":require("@/assets/icons/icon-24-ic-chev-e.svg")}}):_c('img',{style:({ width: '16px' }),attrs:{"src":require("@/assets/icons/icon-24-ic-chev-s.svg")}})]):_vm._e()]),(item.subMenus && !item.hideSubMenu)?_c('div',{staticStyle:{"display":"flex","flex-direction":"column","width":"100%"}},_vm._l((item.subMenus),function(subMenu){return _c('router-link',{key:subMenu.routeName,staticClass:"submenu",class:_vm.selectedSubMenu == subMenu ? 'submenu-active' : 'submenu-inactive',style:({ height: '28px' }),attrs:{"to":{
          name: `${subMenu.routeName}-view`,
          params: {
            opId: _vm.opId,
          },
        }},nativeOn:{"click":function($event){return _vm.selectSubMenu(subMenu, item)}}},[_c('div',{staticClass:"label-13 submenu-title",style:({ marginLeft: '44px' })},[_vm._v(" "+_vm._s(subMenu.text)+" ")])])}),1):_vm._e()])}),0):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }