import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const pathPrefix = '/:opId';

const routes = [
  {
    path: `${pathPrefix}/media`,
    name: 'media-view',
    component: () => import(/* webpackChunkName: "media" */ '../views/Media.vue'),
  },
  {
    path: `${pathPrefix}/media/:mediaId`,
    name: 'media-info-view',
    component: () => import(/* webpackChunkName: "media" */ '../views/Media.vue'),
  },
  {
    path: `${pathPrefix}/playlists`,
    name: 'playlists-view',
    component: () => import(/* webpackChunkName: "playlists" */ '../views/Playlists.vue'),
  },
  {
    path: `${pathPrefix}/playlists/create`,
    name: 'playlists-create-view',
    component: () => import(/* webpackChunkName: "playlist" */ '../views/Playlists.vue'),
  },
  {
    path: `${pathPrefix}/playlists/:playlistId`,
    name: 'playlists-info-view',
    component: () => import(/* webpackChunkName: "playlist" */ '../views/Playlists.vue'),
  },
  {
    path: `${pathPrefix}/playlists/:playlistId/edit`,
    name: 'playlists-edit-view',
    component: () => import(/* webpackChunkName: "playlist" */ '../views/Playlists.vue'),
  },
  // -------------------------------------------------------------
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
  },
  {
    path: `/devices/link`,
    name: 'devices-link-view',
    component: () => import(/* webpackChunkName: "devices-link" */ '../views/DeviceLink.vue'),
  },
  {
    path: `/login`,
    name: 'login-view',
    component: () => import(/* webpackChunkName: "devices" */ '../views/Login.vue'),
  },
  {
    path: `${pathPrefix}/devices`,
    name: 'devices-view',
    component: () => import(/* webpackChunkName: "devices" */ '../views/Devices.vue'),
  },
  {
    path: `${pathPrefix}/devices/screenshots`,
    name: 'devices-screenshots-view',
    component: () =>
      import(/* webpackChunkName: "devices-screenshots" */ '../views/DeviceScreenshots.vue'),
  },
  {
    path: `${pathPrefix}/layouts`,
    name: 'layouts-view',
    component: () => import(/* webpackChunkName: "layouts" */ '../views/Layouts.vue'),
  },
  {
    path: `${pathPrefix}/screens`,
    name: 'screens-view',
    component: () => import(/* webpackChunkName: "screens" */ '../views/Screens.vue'),
  },
  {
    path: `${pathPrefix}/screens/create`,
    name: 'screens-create-view',
    component: () => import(/* webpackChunkName: "screens" */ '../views/Screens.vue'),
  },
  {
    path: `${pathPrefix}/screens/:screenId`,
    name: 'screens-view-view',
    component: () => import(/* webpackChunkName: "screens" */ '../views/Screens.vue'),
  },
  {
    path: `${pathPrefix}/screens/:screenId/edit`,
    name: 'screens-edit-view',
    component: () => import(/* webpackChunkName: "screens" */ '../views/Screens.vue'),
  },
  {
    path: `${pathPrefix}/icons`,
    name: 'icons-view',
    component: () => import(/* webpackChunkName: "icons" */ '../views/Icons.vue'),
  },
  // -------------------------------------------------------------
  // -------------------------------------------------------------
  {
    path: `${pathPrefix}/stats`,
    name: 'stats-view',
    component: () => import(/* webpackChunkName: "stats" */ '../views/Stats.vue'),
  },
  {
    path: `${pathPrefix}/stats/:mediaId`,
    name: 'stats-info-view',
    component: () => import(/* webpackChunkName: "stats" */ '../views/Stats.vue'),
  },
  {
    path: `${pathPrefix}/soundeditor`,
    name: 'sound-editor',
    component: () => import(/* webpackChunkName: "sound-editor" */ '../views/SoundEditor.vue'),
  },
  {
    path: `${pathPrefix}/smartplug`,
    name: 'smartplug-view',
    component: () => import(/* webpackChunkName: "smartplug" */ '../views/SmartPlug.vue'),
  },
  {
    path: `${pathPrefix}/smartplug/logs`,
    name: 'smartplug-logs-view',
    component: () => import(/* webpackChunkName: "smartplug-logs" */ '../views/SmartPlugLog.vue'),
  },
  {
    path: `${pathPrefix}/announcement`,
    name: 'announcement-view',
    component: () => import(/* webpackChunkName: "stats" */ '../views/Announcement.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
